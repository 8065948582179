<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    //
  }),
  mounted() {
    // this.logout({
    //     makeRequest: false,
    // });
    console.log(this.$auth);
    this.$auth.load()
      .then(() => {
        console.log(`ready ${this.context}`);
        console.log('auth check', this.$auth.check());
        console.log(this.$router.currentRoute);
        if (!this.$auth.check() && this.$route.name !== 'login') {
          this.$router.push({
            name: 'login',
          });
        }
      });
  },
};
</script>
