<template>
  <v-app>
    <v-app-bar app flat dense color="#003f88">
      <v-img
        max-width="100"
        class="ml-2"
        src="@/assets/login-support.png"></v-img>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'Dashboard',
  data() {
    return {};
  },
  methods: {
    logout() {
      this.$auth
        .logout({
          // makeRequest: true,
        });
    },
  },
};
</script>

<style scoped>

</style>
