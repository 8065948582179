<template>
  <v-layout column class="pt-5">
    <v-flex xs12>
      <v-layout class="pt0 pb0">
        <v-flex xs4>
          <h2>Applications</h2>
        </v-flex>
        <v-flex xs4>
          <v-text-field
            label="Find client (email, name, phone)"
            append-icon="mdi-magnify"
            v-model="searchValue"
            :loading="isLoading"
            class="pa-0"
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 class="pt-5">
      <v-data-table
        :headers="headers"
        :items="clients"
        :options.sync="options"
        :server-items-length="countItems"
        :loading="isLoading"
        class="elevation-0"
        disable-filtering
        :footer-props="{
          itemsPerPageOptions: [15],
        }"
        :items-per-page=15
        :page.sync="page"
      >
        <template v-slot:item.trial_up="{ item }">
          <v-checkbox
            :input-value="item.is_trial_show_up === 1"
            @change="() => changeStatus(
              item.id, item.is_trial_show_up === 0 ? 1 : 0, 'is_trial_show_up', item.click_id
              )"
          ></v-checkbox>
          <!--          <v-btn color="success" :loading="isLoading" depressed small-->
          <!--                 v-if="item.is_trial_show_up === '0'"-->
          <!--                 @click="() => changeStatus(item.id, '1', 'is_trial_show_up')">-->
          <!--            Yes-->
          <!--          </v-btn>-->
          <!--          <v-btn color="error" :loading="isLoading" depressed small-->
          <!--                 v-if="item.is_trial_show_up === '1'"-->
          <!--                 @click="() => changeStatus(item.id, '0', 'is_trial_show_up')">-->
          <!--            No-->
          <!--          </v-btn>-->
        </template>
        <template v-slot:item.total_sales="{ item }">
          <v-btn
            @click="() => openDialog(item)"
          >{{ item.total_sales === undefined ? 0 : item.total_sales }}
          </v-btn>
          <!--          <v-btn color="success" :loading="isLoading" depressed small-->
          <!--                 v-if="item.is_membership_paid === '0'"-->
          <!--                 @click="() => changeStatus(item.id, '1', 'is_membership_paid')">-->
          <!--            Yes-->
          <!--          </v-btn>-->
          <!--          <v-btn color="error" :loading="isLoading" depressed small-->
          <!--                 v-if="item.is_membership_paid === '1'"-->
          <!--                 @click="() => changeStatus(item.id, '0', 'is_membership_paid')">-->
          <!--            No-->
          <!--          </v-btn>-->
        </template>
      </v-data-table>
    </v-flex>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Sales Value</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Value"
                  type="number"
                  v-model="currentDialogItem.total_sales"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            :loading="isLoading"
            :disabled="isLoading"
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="isLoading"
            :disabled="isLoading"
            @click="changeSalesValue"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>

import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'Bookings',
  data() {
    return {
      snackbar: false,
      searchValue: '',
      drawer: false,
      drawerForm: {},
      dialog: false,
      page: 1,
      totalDesserts: 0,
      desserts: [],
      options: {},
      currentDialogItem: {},
      headers: [
        // ['your-name', 'your-email', 'telephone', 'location', 'Url']
        {
          text: 'Name',
          sortable: false,
          value: 'name',
        }, {
          text: 'E-mail',
          sortable: false,
          value: 'email',
        }, {
          text: 'Phone',
          sortable: false,
          value: 'phone',
        }, {
          text: 'Meetings',
          value: 'trial_up',
          sortable: false,
        }, {
          text: 'Sales Value',
          value: 'total_sales',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      clients: 'Clients/list',
      countItems: 'Clients/countItems',
      isLoading: 'Clients/isLoading',
    }),
  },
  watch: {
    options: {
      async handler() {
        console.log(this.options);
        await this.$store.dispatch('Clients/getAll', {
          page: this.options.page,
          searchValue: this.searchValue,
        });
      },
      deep: true,
    },
    searchValue: _.debounce(function () {
      this.page = 1;
      this.$store.dispatch('Clients/getAll', {
        page: this.page,
        searchValue: this.searchValue,
      });
    }, 500),
  },
  async mounted() {
    await this.$store.dispatch('Clients/getAll', {
      page: this.page,
      searchValue: this.searchValue,
    });
  },
  methods: {
    openDialog(item) {
      this.currentDialogItem = { ...item };
      this.currentDialogItem.old_total_sales = item.total_sales === undefined
        ? 0 : item.total_sales;
      this.dialog = true;
    },
    async changeSalesValue() {
      await this.$store.dispatch('Clients/saveItem', this.currentDialogItem);
      await this.$store.dispatch('Clients/getAll', {
        page: this.page,
        searchValue: this.searchValue,
      });
      this.dialog = false;
    },
    async changeStatus(id, status, type, clickId) {
      console.log(id, status, type);
      await this.$store.dispatch('Clients/changeStatus', {
        id,
        status,
        type,
        click_id: clickId,
      });
      await this.$store.dispatch('Clients/getAll', {
        page: this.page,
        searchValue: this.searchValue,
      });
    },
  },
};
</script>

<style>

</style>
