<template>
  <div class="home">
<!--    <img alt="Vue logo" src="../assets/logo.png">-->
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
export default {
  name: 'Home',
  created() {
    if (this.$auth.check()) {
      console.log('AUTH');
      this.$router.push({
        name: 'Bookings',
      });
    }
  },
};
</script>
