import Vue from 'vue';
import VueRouter from 'vue-router';
import Bookings from '@/views/Bookings.vue';
import Dashboard from '../components/Dashboard.vue';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    children: [
      {
        path: 'bookings',
        name: 'Bookings',
        component: Bookings,
      },
    ],
    meta: { auth: true },
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
