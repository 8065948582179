import axios from 'axios';

// State object
const dataState = {
  loadings: [],
  list: [],
  countItems: 0,
  item: {
    id: 0,
    fio: '',
    email: '',
    phone: '',
  },
};
// Getter functions
const getters = {
  list(state) {
    return state.list;
  },
  item(state) {
    return state.item;
  },
  countItems(state) {
    return state.countItems;
  },
  isLoading(state) {
    return state.loadings.length !== 0;
  },
};
// Actions
const actions = {
  async getAll({ commit }, { page, searchValue }) {
    console.log('searchValue:', searchValue);
    commit('ADD_LOADINGS', 'getAll');
    const response = await axios.get('/clients', {
      params: {
        page,
        search_value: searchValue,
      },
    });
    commit('SAVE_LIST', response.data.data);
    commit('SAVE_COUNT_ITEMS', response.data.total);
    commit('REMOVE_LOADINGS', 'getAll');
  },

  // async getUser({ commit }, id) {
  //   commit('SAVE_ITEM', null);
  //   const response = await axios.get(`/clients/${id}`);
  //   console.log(response.data);
  //   commit('SAVE_ITEM', response.data);
  // },

  async changeStatus({ commit }, data) {
    commit('ADD_LOADINGS', 'create');
    console.log(data);
    const response = await axios.post('/clients', data);

    console.log(response);

    commit('REMOVE_LOADINGS', 'create');
  },

  async saveItem({ commit }, data) {
    commit('ADD_LOADINGS', 'save');
    console.log(data);
    const response = await axios.post(`/clients/${data.id}`, data);

    console.log(response);

    commit('REMOVE_LOADINGS', 'save');
  },
  //
  // async remove({ dispatch }, client) {
  //   console.log(client);
  //   const response = await axios.delete(`/clients/${client.id}`);
  //
  //   console.log(response);
  //
  //   dispatch('getAll');
  // },
  //
  // async update({ dispatch }, client) {
  //   console.log(client);
  //   const response = await axios.patch(`/clients/${client.id}`, client);
  //
  //   console.log(response);
  //
  //   dispatch('getAll');
  // },
};
// Mutations
const mutations = {
  SAVE_COUNT_ITEMS(state, data) {
    state.countItems = data;
  },
  SAVE_LIST(state, data) {
    state.list = data;
  },
  ADD_LOADINGS(state, data) {
    state.loadings.push(data);
  },
  REMOVE_LOADINGS(state, data) {
    state.loadings = state.loadings.filter((item) => item !== data);
  },
  // ADD_CLIENT(state, data) {
  //   state.list.push(data);
  // },
  // SAVE_ITEM(state, data) {
  //   state.item = data;
  // },
  // SET_VARIABLE_1(state, data) {
  //     state.variable2 = data;
  // },
  // SET_VARIABLE_2(state, data) {
  //     state.variable2 = data;
  // },
};
export default {
  namespaced: true,
  state: dataState,
  getters,
  actions,
  mutations,
};
